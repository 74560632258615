// Capypara (c) 2024 Maximilian Reif
import { useState, useRef, useEffect } from 'react';
import { useMutation } from "@apollo/client";
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import SendIcon from '@mui/icons-material/Send';
import ClearIcon from '@mui/icons-material/Clear';
import dayjs from 'dayjs';
import {ConfirmButton, FadeModal} from '../common/CustomComponents.js';
import Api from '../common/GraphQL.js';

/// Render the badge for the eventstate
function EventBadge({state}) {
  var text = '';
  if (state === 0) {
    text = 'Durchführung geplant';
  } else if (state === 1) {
    text = 'Event abgeschlossen';
  } else if (state === 2) {
    text = 'Event abgesagt';
  } else { return; } 
  return <Chip size="small" color={''} label={text}/>
}

/// Render the badge for the applicationstate
function ApplicationBadge({state}) {
  var text = '';
  if (state === 0) {
    text = 'Anmeldung erhalten';
  } else if (state === 1) {
    text = 'Fixplatz';
  } else if (state === 2) {
    text = 'Warteliste';
  } else if (state === 3) {
    text = 'Abgemeldet';
  } 
  else { return; } 
  return <Chip size="small" color={''} label={text}/>
}

/// Render event
function Event({event, allEvents, lastRegistration, index}) {
  const [lastRegistrationId, setLastRegistration] = lastRegistration;
  const didApplyPopupState = useState(false);
  const mutationOptions = { variables: { eventid: event.id }, ignoreResults: true, refetchQueries: [Api.Query.GET_USER_DATA]}
  const [registerEvent] = useMutation(Api.Mutation.REGISTER_EVENT, mutationOptions);
  const [cancelEvent] = useMutation(Api.Mutation.CANCEL_USER_APPLICATION, mutationOptions);
  const ref = useRef();

  useEffect(()=>{
    if (event.id === lastRegistrationId) {
      ref.current.scrollIntoView({block: 'start', behavior: 'smooth'});
      didApplyPopupState[1](true);
    }
    //setLastRegistration(null);
  // we only want to scroll on update event... so this is fine
  // eslint-disable-next-line
  }, [event]);

  return <div className="fade-in" style={{animationDelay: `${event.id === lastRegistrationId ? 0.2 : index * 0.1}s`}}>
    <Paper sx={{opacity: (!allEvents || (event.applicationstate == null && event.eventstate === 0 )) ? 1 : 0.5}} className="event" elevation={(!allEvents || (event.applicationstate == null && event.eventstate === 0 )) ? 6 : 2}>
      <div id={event.id} ref={ref} style={{position: 'absolute', marginTop: '-20px'}}/>
      <div className="inner-box">
        <h2>{event.name}</h2>
        <p className="date-label"><EventAvailableIcon sx={{fontSize: 14, verticalAlign: -2 }}/> {dayjs(event.eventstart).format('DD.MM')} {event.eventend && ('- ' + dayjs(event.eventend).format('DD.MM'))}</p>
        <Stack direction="row" spacing={1}>
          <EventBadge state={event.eventstate}/>
          <ApplicationBadge state={event.applicationstate}/>
        </Stack>
        <p>{event.description}</p>
        {!allEvents &&event.eventstate === 0 && <>
          {event.applicationstate === 1 && event.whatsapplink && <Button size="small" href={event.whatsapplink} target="_blank" startIcon={<WhatsAppIcon/>} sx={{marginRight: 1, marginBottom: 1}} variant="text" color="primary" >WhatsApp Gruppe</Button>}
          <Button href={'mailto:' + event.mail + '?subject=' + encodeURIComponent(event.name)} target="_blank" size="small" startIcon={<SendIcon/>} sx={{marginRight: 1, marginBottom: 1}} variant="text" color="primary">E-Mail</Button>
          <ConfirmButton description="Möchtest du dich wirklich abmelden?" onClick={()=>{cancelEvent(); setLastRegistration(null);}} size="small"  startIcon={<ClearIcon/>} sx={{marginRight: 1, marginBottom: 1}} variant="text" color="primary">Abmelden</ConfirmButton>
        </>}
        {allEvents && event.applicationstate === null &&event.eventstate === 0 && <>
          <Button onClick={async()=>{registerEvent(); setLastRegistration(event.id);}} size="small" startIcon={<PersonAddIcon/>} sx={{marginRight: 1, marginBottom: 1}} variant="text" color="primary" >Anmelden</Button>
        </>}
      </div>
    </Paper>
  </div>
}

/// Render an event section e.g. confirmed events
function EventSection({name, eventList, color, allEvents, lastRegistration}) {
  const [filtered, setFiltered] = useState(allEvents);
  const filteredList = eventList.filter(x=>(x.applicationstate == null && x.eventstate === 0 ));
  const canFilter = allEvents && (filteredList.length !== eventList.length);
  if (filtered) eventList = filteredList
  return <Paper className="paper" elevation={6}>
    <div className={'color-bar ' + color}></div>
    <div className="inner-box-paper">
      <h2>
        {(canFilter ? filtered ? 'Geplante ' : 'Alle ' : '') + name}
        {canFilter &&
          <IconButton sx={{marginTop:-0.75}} size="small" color="primary" onClick={()=>setFiltered(!filtered)}>
            {filtered?<FilterAltOffIcon sx={{fontSize: 20}}/>:<FilterAltIcon sx={{fontSize: 20}}/>}
          </IconButton>
        }
      </h2>
      {eventList.map((x, i)=><Event index={i} key={x.id} event={x} lastRegistration={lastRegistration} allEvents={allEvents}/>)}
    </div>
  </Paper>
}

/// Render all events
export default function Events({events}) {
  const lastRegistration = useState(null); 
  const confirmedEvents = events.filter(x => x.eventstate === 0 && x.applicationstate === 1);
  const wlEvents = events.filter(x => x.eventstate === 0 && (x.applicationstate === 0 || x.applicationstate === 2));
  return <>   
    {confirmedEvents.length > 0 && <EventSection name="Bestätigte Events" lastRegistration={lastRegistration} eventList={confirmedEvents} color="green"/>}
    {wlEvents.length > 0 && <EventSection name="Ausstehende Anmeldungen" lastRegistration={lastRegistration} eventList={wlEvents} color="orange"/>}
    <EventSection name="Events 2025" eventList={events} allEvents={true} lastRegistration={lastRegistration} color="default"/>
  </>
}