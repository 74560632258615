// Capypara (c) 2024 Maximilian Reif
import { gql } from "@apollo/client";

/// This is the main query and gets most of the data
const GET_USER_DATA = gql`
  query GetUserData {
    getUser {
      id
      firstname
      lastname
      emergencyname
      emergencynumber
      birthday
      pilot
      flyinghours
      mail
      isorganizer
      events {
        id
        name
        description
        eventstart
        eventend
        year
        eventstate
        applicationstate
        submittimestamp
        whatsapplink
        mail
        orgadetails {
          plannedpax
          currentpax
          newpax
          waitlistpax
          myevent
        }
      }
    }
  }
`;

/// This query fetches all applications for a specific event for managing them
const GET_APPLICATIONS_ORGANIZER = gql`
  query GetApplicationsOrganizer($eventid: String!) {
    getApplicationsOrganizer(eventid: $eventid) {
      id
      submittimestamp
      applicationstate
      account {
        firstname
        lastname
        emergencyname
        emergencynumber
        birthday
        pilot
        flyinghours
        mail
      }
    }
  }
`;

/// User query... allows to create or update the user object
const UPSERT_USER = gql`
  mutation UpsertUser($account: AccountInput!) {
    upsertUser(account: $account)
  }
`;

/// User query... registers the user for an event
const REGISTER_EVENT = gql`
  mutation RegisterEvent($eventid: String!) {
    registerEvent(eventid: $eventid)
  }
`;

/// User query... deregisters the user for an event
const CANCEL_USER_APPLICATION = gql`
mutation RegisterEvent($eventid: String!) {
    cancelUserApplication(eventid: $eventid)
  }
`;

/// Organizer query... changes the event settings
const UPDATE_EVENT = gql`
  mutation updateEvent($event: EventInput!, $notification: String) {
    updateEvent(event: $event, notification: $notification)
  }
`;

/// Organizer query... marks the event as concluded
const CONCLUDE_EVENT = gql`
mutation ConcludeEvent($eventid: String!) {
    concludeEvent(eventid: $eventid)
  }
`;

/// Organizer query... marks the event as cancelled
const CANCEL_EVENT = gql`
mutation cancelEvent($eventid: String!) {
    cancelEvent(eventid: $eventid)
  }
`;

/// Organizer query... acceppt applications
const ACCEPT_APPLICATIONS = gql`
mutation AcceptApplications($applicationids: [Int]!) {
    acceptApplications(applicationids: $applicationids)
  }
`;

/// Organizer query... move applications to waitlist
const MOVE_TO_WAITLIST = gql`
mutation MoveToWaitlist($applicationids: [Int]!) {
    moveToWaitlist(applicationids: $applicationids)
  }
`;

/// Organizer query... cancel applications
const CANCEL_APPLICATIONS = gql`
mutation CancelApplications($applicationids: [Int]!) {
    cancelApplications(applicationids: $applicationids)
  }
`;

/// Export all gql's in one Api object
const Api = {
  Query: {
    GET_USER_DATA,
    GET_APPLICATIONS_ORGANIZER,
  },
  Mutation: {
    UPSERT_USER,
    UPDATE_EVENT,
    REGISTER_EVENT,
    CANCEL_USER_APPLICATION,
    ACCEPT_APPLICATIONS,
    MOVE_TO_WAITLIST,
    CANCEL_APPLICATIONS,
    CONCLUDE_EVENT,
    CANCEL_EVENT
  }
}

export default Api;
