// Capypara (c) 2024 Maximilian Reif
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { ApolloProvider, ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { removeTypenameFromVariables } from '@apollo/client/link/remove-typename';
import { setContext } from "@apollo/client/link/context";
import App from './App';
import './index.css';

/// Configure apollo client to hide typenames and to authenticate with the api using the auth0 id token
const removeTypenameLink = removeTypenameFromVariables();
const ApolloProviderWithAuth = ({ children }) => {
  // Shortly about the id token:
  // Typically you'd use an auth token, but we do rbac on our own. So we don't need it.
  // On top this allows us to easily replace auth0
  const { getIdTokenClaims, isAuthenticated } = useAuth0();
  const httpLink = createHttpLink({ uri: process.env.REACT_APP_API });
  const authLink = setContext(async (_, { headers }) => {
    if (isAuthenticated) {
      const tokenClaims = await getIdTokenClaims();
      const idToken = tokenClaims.__raw;
      return { headers: {...headers, Authorization: `Bearer ${idToken}` }};
    }
    return headers;
  });
  const client = new ApolloClient({ link: removeTypenameLink.concat(authLink.concat(httpLink)), cache: new InMemoryCache() });
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

/// Root of the application
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Auth0Provider domain="dev-3cs66gf2nfzfag4b.us.auth0.com"
    clientId="oqDAcUbQNAb82yr3pX4hnqsBScCFY2aW"
    useRefreshTokens={true} 
    cacheLocation="localstorage" // not extremely safe, but we control all our code, might disable later
    authorizationParams={{redirect_uri: window.location.origin }}
    onRedirectCallback={appState=>window.history.replaceState({}, document.title, appState?.returnTo || window.location.pathname)}>
    <ApolloProviderWithAuth>
      <App/>
    </ApolloProviderWithAuth>
  </Auth0Provider>
);

