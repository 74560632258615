// Capypara (c) 2024 Maximilian Reif
import { useState } from 'react';
import { useMutation, useQuery } from "@apollo/client";
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import dayjs from 'dayjs';
import Api from '../common/GraphQL.js';

/// Renders accept, waitlist or cancel buttons
function AcceptRejectButtons({sx, appState, toggleWl, toggleAccept, toggleCancel}) {
  return <Stack direction="row" spacing={0} sx={sx}>
   {(appState !== 1) && <IconButton onClick={toggleAccept}><CheckIcon sx={{fontSize:16}} color="success"/></IconButton>}
   {(appState === 0) && <IconButton onClick={toggleWl}><FormatListNumberedIcon sx={{fontSize:16}} color="warning"/></IconButton>}
   {(appState === 1) && <IconButton onClick={toggleCancel}><ClearIcon sx={{fontSize:16}} color="error"/></IconButton>}
  </Stack>
}

/// Render a participant row
function Participant({id, application, state, disabled}) {
  const [expanded, setExpanded] = useState(false);

  const now = dayjs()
  const age = now.diff(dayjs(application.account.birthday), 'year') 
  const submitts = dayjs(application.submittimestamp).format('DD.MM.YYYY hh:mm:ss');
  const isWl = state.current[application.id] === 2;
  const isAccept = state.current[application.id] === 1;
  const isCancel = state.current[application.id] === 3;
  const bgColor = isAccept ? '#5A14' : isWl ? '#FEC' : isCancel ? '#F554' : (id%2)===0?'#FFF':'#F5F6F7';
 
  return <div style={{fontSize: 10, margin: 0,backgroundColor: bgColor, borderTop: id !== 0 ? '' : 'solid #777 0.5px', borderBottom: 'solid #777 0.5px'}}>
    <div style={{margin: 0, marginLeft: 5}} className="inner-box">
      {!disabled && 
        <AcceptRejectButtons 
          toggleAccept={()=>state.setState({...state.current, [application.id]: isAccept ? 0 : 1})}
          toggleWl={()=>state.setState({...state.current, [application.id]: isWl ? 0 : 2})}
          toggleCancel={()=>state.setState({...state.current, [application.id]: isCancel ? 0 : 3})}
          appState={application.applicationstate} sx={{position: 'absolute', right: 25, marginTop: 0.25}}/>
      }
      <div style={{paddingTop: 8, marginBottom:-5, fontSize: 14}}>
        #{id} <strong style={{cursor: 'pointer'}} onClick={()=>setExpanded(!expanded)}>
        {application.account.firstname} {application.account.lastname} </strong>
        ({age}, {application.account.pilot ? (application.account.flyinghours + ' h'): 'Muggel'})
      </div>
      {expanded && <>
        <div style={{paddingTop: 10}}><strong>Notfall: </strong>{application.account.emergencynumber} ({application.account.emergencyname})</div>
        <div><strong>Angemeldet: </strong>{submitts}</div>
        <div style={{marginBottom: 0}}><strong>Mail: </strong>{application.account.mail}</div>
      </>}
    </div>
  </div>
}

/// Renders a participant list (filtered by appState)
function ParticioantList({name, event, color, appState, applications}) {
  // Mutations and state
  const mutationOptions = {refetchQueries: [Api.Query.GET_USER_DATA, Api.Query.GET_APPLICATIONS_ORGANIZER]};
  const [accept] = useMutation(Api.Mutation.ACCEPT_APPLICATIONS, mutationOptions);
  const [waitlist] = useMutation(Api.Mutation.MOVE_TO_WAITLIST, mutationOptions);
  const [cancel] = useMutation(Api.Mutation.CANCEL_APPLICATIONS, mutationOptions);
  const [current, setState] = useState({});

  // List is the list with all items == appState, newX are used to track the selection
  const list = applications.filter(x=>x.applicationstate === appState)
  const newWl = applications.filter(x=>current[x.id]===2);
  const newAccept = applications.filter(x=>current[x.id]===1);
  const newCancel = applications.filter(x=>current[x.id]===3);

  // Click handler
  const resetState = (list)=>{
   setState(oldState=>{
      const newState = {...oldState};
      for (const x of list) newState[x.id] = 0;
      return newState
    })
  }
  const confirmAccept = ()=>{
    resetState(newAccept);
    accept({variables: { applicationids: newAccept.map(x=>x.id) }});
  }
  const confirmWaitlist = ()=>{
    resetState(newWl);
    waitlist({variables: { applicationids: newWl.map(x=>x.id) }});
  }
  const confirmCancel = ()=>{
    resetState(newCancel);
    cancel({variables: { applicationids: newCancel.map(x=>x.id) }});
  }
  
  const disabled = event.eventstate !== 0;
  return <Paper className="paper" elevation={6}>
    <div className={'color-bar ' + color}></div>
    <div className="inner-box-paper">
      <h2 style={{marginBottom: 8, paddingBottom: 0}}>
        {name}{' '}
        <Chip size="small" color="" label={list.length}/>
      </h2>
      <Stack direction="row" spacing={1}>
        {!disabled && newAccept.length > 0 && <Button onClick={confirmAccept} size="small" sx={{marginTop: 1}} variant="contained" color="success">{newAccept.length}x zusagen</Button>}
        {!disabled && newWl.length > 0 && <Button onClick={confirmWaitlist} size="small" sx={{marginTop: 1}} variant="contained" color="warning">{newWl.length}x Warteliste</Button>}
        {!disabled && newCancel.length > 0 && <Button onClick={confirmCancel} size="small" sx={{marginTop: 1}} variant="contained" color="error">{newCancel.length}x Absagen</Button>}
      </Stack>
      <div style={{marginTop: 10}}>
        {list.map((x,i)=><Participant key={x.id} disabled={event.eventstate !== 0} state={{current, setState}} application={x} id={i}/>)}
      </div>
    </div>
  </Paper>
}

/// Renders the 4 participant subsections
export default function ParticipantSection({event}) {
  const { loading, error, data } = useQuery(Api.Query.GET_APPLICATIONS_ORGANIZER, {
    variables: {eventid: event.id},
    fetchPolicy: 'network-only', 
    nextFetchPolicy: 'network-only', 
  });
  if (loading) return <>Loading...</>;
  if (error) return console.log(error);
  const applications = data.getApplicationsOrganizer;

  return <>
    <ParticioantList appState={0} name="Frische Anmeldungen" color="default" applications={applications} event={event}/>
    <ParticioantList appState={1} name="Bestätigte Teilnehmer" color="green" applications={applications} event={event}/>
    <ParticioantList appState={2} name="Warteliste" color="orange" applications={applications} event={event}/>
    <ParticioantList appState={3} name="Abgesagt" color="red" applications={applications} event={event}/>
  </>
}