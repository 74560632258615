// Capypara (c) 2024 Maximilian Reif
import { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Link,useLocation, Navigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "@apollo/client";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { ConfirmProvider } from "material-ui-confirm";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import { IconButton } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import Countdown from './Countdown.js';
import Profile from './user/Profile.js'
import Events from './user/Events.js'
import ManageView from './organizer/Manage.js'
import ManageEventDetail from './organizer/EventEditor.js'
import logo from './assets/jplus.png';
import './App.css';
import Api from './common/GraphQL.js'

/// Custom color theme
const customTheme = createTheme({
  palette: {
    primary: {
      main: 'rgb(3, 41, 70)',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#fffd',
      contrastText: 'rgb(3, 41, 70)',
    },
    info: {
      main: 'rgb(80, 41, 50)',
      contrastText: 'rgb(80, 41, 50)',
    },
    success: {
      main: '#5A1',
      contrastText: '#FFFFFF',
    },
    warning: {
      main: '#f90',
      contrastText: '#FFFFFF',
    }
  },
});

/// Logout button
const LogoutButton = () => {
  const { logout } = useAuth0();
  const handleLogout = () => { localStorage.removeItem("active"); logout({ returnTo: window.location.origin }); };
  return <div className="logout">
    <IconButton  sx={{ p: 0 }} color="secondary" onClick={handleLogout}>
      <LogoutIcon sx={{ fontSize: 18 }}/>
    </IconButton>
  </div>;
};

/// Title bar
function TitleBar() {
  return <div className="title-box">
    <img className="logo" src={logo} alt="Logo" width="200"/>
    <div className="title">jugend
      <span className="juplu">+</span>{' '}
      <span className="eventsystem">event system</span>
    </div>
    <LogoutButton/>
  </div>
}

/// Navigation buttons
function NavBar({showManagement}) {
  const location = useLocation();
  const isActive = (path) => location.pathname.startsWith(path) ? 'contained' : 'outlined';
  return <div className="nav"> 
    <ButtonGroup fullWidth variant="outlined" aria-label="Basic button group">
      <Button component={Link} to="/profile" variant={isActive('/profile')}>Profil</Button>
      <Button component={Link} to="/events" variant={isActive('/events')}>Events</Button>
      {showManagement && <Button component={Link} to="/manage" variant={isActive('/manage')}>Manage</Button>}
    </ButtonGroup>
  </div>
}

/// This component takes care of the routing, we only do this if we have an authenticated user
function Router() {
  // Main queryy
  const { loading, error, data } = useQuery(Api.Query.GET_USER_DATA);
  if (error) return console.log(error);
  if (loading) return <>Loading...</>;

  // On first login, user can be null –> Only allow to create a orifile
  if (!data.getUser) return <Profile/>
  const {events, isorganizer, ...user} = data.getUser;  

  return <BrowserRouter>
    <NavBar showManagement={isorganizer}/>
    <Routes>
      <Route path="/profile" element={<Profile user={user} />}/>
      <Route path="events" element={<Events events={events}/>}/>
      {isorganizer && <Route path="manage" element={<ManageView allEvents={events}/>}/>}
      {isorganizer && <Route path="manage/:id" element={<ManageEventDetail events={events}/>}/>}
      <Route path="*" element={<Navigate to="/events" replace />}/>
    </Routes>
  </BrowserRouter>
}


/// This is the main event system
function EventSystem() {
  const {isAuthenticated, isLoading, getIdTokenClaims, loginWithRedirect} = useAuth0();  

  /// Auto login if not authenticated
  useEffect(() => {
    if (!isAuthenticated && !isLoading) loginWithRedirect();
  }, [isAuthenticated, isLoading, loginWithRedirect]);

  // Auto login if id token expired
  if (isAuthenticated) {
    getIdTokenClaims().then(tokenClaims => {
      const isExpired = (Date.now() / 1000) > tokenClaims.exp
      if (isAuthenticated && isExpired) loginWithRedirect();
    });
  }

  return <>
    <TitleBar/>
    <div className="main-box">
      {isLoading && <div>Authenticating...</div>}
      {isAuthenticated && <Router/>}
    </div>
  </>
}

/// The main entry point... of course we only show the EventSystem on xmas
export default function App() {
  const [active, setActive] = useState(localStorage.getItem("active") || dayjs('2024-12-24T13:00:00').diff(dayjs()) < 0);
  return <ThemeProvider theme={customTheme}>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ConfirmProvider>
        {active ? <EventSystem/> : <Countdown setActive={setActive}/>}
      </ConfirmProvider>
    </LocalizationProvider>
  </ThemeProvider>
}