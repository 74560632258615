// Capypara (c) 2024 Maximilian Reif
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import { useConfirm } from "material-ui-confirm";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';

/// A textfield, that can update form value objects
export function FormTextField({values, name, onChange, label, disabled, allowNull}) {
  return <TextField
    disabled={disabled}
    autoComplete='nope'
    error={!allowNull && !values[name]}
    name={name}
    label={label}
    value={values[name] || ''}
    onChange={e=>onChange({...values, [e.target.name]: e.target.value || null})}
  />
}

/// Event badge that shows if the event is scheduled, concluded or cancelled
export function EventStateChip({event}) {
  return <>
    {event.eventstate === 0 && <Chip size="small" color="success" label={'Geplant'}/>}
    {event.eventstate === 1 && <Chip size="small" color="" label={'Abgeschlossen'}/>}
    {event.eventstate === 2 && <Chip size="small" color="" label={'Abgesagt'}/>}
  </>
}

export function ConfirmButton(props) {
  const confirm = useConfirm();
  const handleClick = (e)=>{
    e.preventDefault()
    confirm({ title: props.title || 'Bist du sicher?',
              description: props.description || 'Beschreibung',
              confirmationText: props.confirmationtext || 'Ja',
              cancellationText: props.cancellationtext || 'Nein'})
      .then(() => {props.onClick(e);})
      .catch(() => {});
    
  }
  return <Button {...props} onClick={handleClick}/>
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #ddd',
  boxShadow: 24,
  p: 4,
};

/// Modeal view that fades
export function FadeModal({state, title, text}) {
  const [open, setOpen] = state;

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={()=>setOpen(false)}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              {title}
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              {text}
            </Typography>
            <Button size="small" sx={{ mt: 1, ml:-2.5 }} color="primary" variant="text" onClick={()=>setOpen(false)}>Ok</Button>

          </Box>

        </Fade>
      </Modal>
    </div>
  );
}