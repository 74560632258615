// Capypara (c) 2024 Maximilian Reif
import { useEffect, useState } from 'react';
import { useMutation } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import FormControlLabel from '@mui/material/FormControlLabel';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import {FormTextField} from '../common/CustomComponents.js'
import Api from '../common/GraphQL.js';

function ProfileView({apiUser, user}) {
  const [formValues, setFormValues] = useState(apiUser || {birthday: null});
  const [upsertUser] = useMutation(Api.Mutation.UPSERT_USER, {refetchQueries: [Api.Query.GET_USER_DATA], ignoreResults: true});

  // If there is no user yet by the api, fill form with auth user data
  useEffect(() => {
    if (!apiUser) setFormValues({firstname: user.given_name, lastname: user.family_name, mail: user.email, pilot: true, birthday: null});
  }, [apiUser, user]);

  // Form handler
  const handleIntChange = e=>setFormValues({...formValues, [e.target.name]: parseInt(e.target.value)});
  const handleSwitchChange = e=>setFormValues({...formValues, flyinghours: null, pilot: e.target.checked});
  const handleDateChange = date=>setFormValues({...formValues, birthday: date && date.format('YYYY-MM-DD')});
  const handleSubmit = (event) => {
    event.preventDefault();
    const {id: _, ...account} = formValues;
    upsertUser({ variables: { account } })
  };

  // Validation check if form can be submitted
  let validated = true;
  for (const x of ['firstname', 'lastname', 'mail', 'emergencyname', 'emergencynumber', 'pilot', 'flyinghours']) {
    if (x === 'pilot' && formValues[x] != null) continue;
    if (x === 'flyinghours' && !formValues.pilot) continue;
    if (!formValues[x] || formValues[x] === 'Invalid Date') validated = false;
  }

  return <Paper elevation={6}>
    <div className="inner-box">
      <h2>Mein Account</h2>
      <Stack spacing={2}>
        <FormTextField name="firstname" label="Vorname" values={formValues} onChange={setFormValues}/>
        <FormTextField name="lastname" label="Nachname" values={formValues} onChange={setFormValues}/>
        <FormTextField name="mail" label="E-Mail (über die wir dich erreichen)" values={formValues} onChange={setFormValues}/>
        <FormTextField name="emergencyname" label="Notfallkontakt" values={formValues} onChange={setFormValues}/>
        <FormTextField name="emergencynumber" label="Notfallkontakt Telefonnummer" values={formValues} onChange={setFormValues}/>
        <DatePicker sx={{ mb: 2 }} label="Geburtstag" value={formValues.birthday && dayjs(formValues.birthday)} format="DD.MM.YYYY" onChange={handleDateChange}/>
        <FormControlLabel sx={{ mb: 2 }} control={ <Switch checked={(formValues.pilot === undefined) ? true : formValues.pilot} onChange={handleSwitchChange}/>} label="Ich habe einen Flugschein"/>
        {formValues.pilot &&
          <TextField sx={{ mb: 2 }} error={!formValues.flyinghours} id="flyinghours" label="Anzahl Flugstunden" name="flyinghours" onChange={handleIntChange} value={formValues.flyinghours || ''}
            slotProps={{input: {endAdornment: <InputAdornment position="end">h</InputAdornment>}}}
          />
        }
        <Button disabled={!validated} variant="text" onClick={handleSubmit}>Speichern</Button>
      </Stack>
    </div>
  </Paper>
}

/// Export profile view
export default function Profile({user}) {
  const auth = useAuth0();  
  return <ProfileView apiUser={user} user={auth.user}/>
}