// Capypara (c) 2024 Maximilian Reif
// This file is ugly and will remain so
import React, {useEffect, useRef, useState} from 'react';
import * as THREE from 'three';
import textureImage from './assets/bg.jpg';
import snowflake1 from './assets/snowflake1.png';
import snowflake2 from './assets/snowflake2.png';
import snowflake3 from './assets/snowflake3.png';
import snowflake4 from './assets/snowflake4.png';
import snowflake5 from './assets/snowflake5.png';
import dayjs from 'dayjs';
import './Countdown.css';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';

export default function WinterCanvas({setActive}){
  const mountRef = useRef(null);

  useEffect(() => {
    // Set up the scene
    const scene = new THREE.Scene();

    // Set up the camera
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 1, 2000);
    camera.position.z = 1000;

    // Fog
    scene.fog = new THREE.FogExp2(0x000000, 0.001);

    // Textures
    const textureLoader = new THREE.TextureLoader();
    const sprite1 = textureLoader.load(snowflake1);
    const sprite2 = textureLoader.load(snowflake2);
    const sprite3 = textureLoader.load(snowflake3);
    const sprite4 = textureLoader.load(snowflake4);
    const sprite5 = textureLoader.load(snowflake5);

    const geometry = new THREE.BufferGeometry();
    const vertices = [];
    for (let i = 0; i < 1000; i++) {
        const x = Math.random() * 1500 - 750;
        const y = Math.random() * 1500 - 750;
        const z = Math.random() * 1500 - 750;
        vertices.push(x, y, z);
    }

    geometry.setAttribute("position", new THREE.Float32BufferAttribute(vertices, 3));
    const materials = [];
    const parameters = [
        [[1.0, 0.2, 0.5], sprite2, 4],
        [[0.95, 0.1, 0.5], sprite3, 2],
        [[0.9, 0.05, 0.5], sprite1, 3],
        [[0.85, 0, 0.5], sprite5, 6],
        [[0.8, 0, 0.5], sprite4, 5],
    ];

    for (let i = 0; i < parameters.length; i++) {
        const color = parameters[i][0];
        const sprite = parameters[i][1];
        const size = parameters[i][2];
        materials[i] = new THREE.PointsMaterial({
            size: size,
            map: sprite,
            blending: THREE.AdditiveBlending,
            depthTest: false,
            transparent: true,
        });
        materials[i].color.setHSL(color[0], color[1], color[2]);
        const particles = new THREE.Points(geometry, materials[i]);
        particles.rotation.x = Math.random() * 6;
        particles.rotation.y = Math.random() * 6;
        particles.rotation.z = Math.random() * 6;
        scene.add(particles);
    }

    const resizeBackground = (tex)=>{
      const aspectRatio = tex.image.width / tex.image.height;
        const windowAspectRatio = window.innerWidth / window.innerHeight;
        if (windowAspectRatio < aspectRatio) {
            tex.repeat.set(windowAspectRatio / aspectRatio, 1);
            tex.offset.set((1 - windowAspectRatio / aspectRatio) / 1.13, 0);
        } else {
            tex.repeat.set(1, aspectRatio / windowAspectRatio);
            tex.offset.set(0, (1 - aspectRatio / windowAspectRatio) / 2);
        }
    }

    const backgroundTexture = textureLoader.load(textureImage, tex => {
      scene.background = tex;
      resizeBackground(tex);
    })


    const renderer = new THREE.WebGLRenderer();
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(window.innerWidth, window.innerHeight);
    mountRef.current.appendChild(renderer.domElement);

    let mouseX = 0;
    let mouseY = 0;
    let windowHalfX = window.innerWidth / 2;
    let windowHalfY = window.innerHeight / 2;

    // Animate the scene
    const animate = () => {
      requestAnimationFrame(animate);
      const time = Date.now() * 0.00003;
      camera.position.x += ( mouseX - camera.position.x ) * 0.05;
      camera.position.y += ( - mouseY - camera.position.y ) * 0.05;
      camera.lookAt(scene.position);
      for (let i = 0; i < scene.children.length; i++) {
          const object = scene.children[i];
          if (object instanceof THREE.Points) {
              object.rotation.y = time * (i < 4 ? i + 1 : -(i + 1));
          }
      }
      for (let i = 0; i < materials.length; i++) {
          const color = parameters[i][0];
          const h = (360 * (color[0] + time) % 360) / 360;
          materials[i].color.setHSL(h, color[1], color[2]);
      }
      renderer.render(scene, camera);
    };

    const onPointerMove =(event)=>{
      mouseX = event.clientX - windowHalfX;
      mouseY = event.clientY - windowHalfY;
    }

    const onTouchMove =(event)=>{
      event.preventDefault();
      mouseX = event.touches[0].clientX - windowHalfX;
      mouseY = event.touches[0].clientY - windowHalfY;
    }

    // Resize the renderer on window resize
    const onWindowResize = () => {
      windowHalfX = window.innerWidth / 2;
        windowHalfY = window.innerHeight / 2;
        camera.aspect = window.innerWidth / window.innerHeight;
        resizeBackground(backgroundTexture);
        camera.updateProjectionMatrix();
        renderer.setSize(window.innerWidth, window.innerHeight);
    };

    animate();
    window.addEventListener('resize', onWindowResize);
    mountRef.current.addEventListener("pointermove", onPointerMove);
    mountRef.current.addEventListener("ontouchmove", onTouchMove, { passive: false });
    mountRef.current.addEventListener("touchstart", onTouchMove, { passive: false });
    mountRef.current.style.overflow = 'hidden';

    mountRef.current.style.userSelect = 'none';
    mountRef.current.style.webkitUserSelect = 'none';
    mountRef.current.style.MozUserSelect = 'none';
    mountRef.current.style.msUserSelect = 'none';
    mountRef.current.style.webkitUserCallout = 'none';
    mountRef.current.style.touchAction = 'none';

    return () => {
        window.removeEventListener('resize', onWindowResize);
    };
  }, []);
  return <>
  <div style={{position:'absolute', overscrollBehavior: 'none', overflow: 'scroll'}} ref={mountRef}></div>
    <Countdown setActive={setActive}/>
  </>;
};

function getTime(now) {
  const christmasDate = dayjs('2024-12-24T13:00:00');
  const days = christmasDate.diff(now, 'day');
  const hours = christmasDate.subtract(days, 'day').diff(now, 'hour');
  const minutes = christmasDate.subtract(days, 'day').subtract(hours, 'hour').diff(now, 'minute');
  const seconds = christmasDate.subtract(days, 'day').subtract(hours, 'hour').subtract(minutes, 'minute').diff(now, 'second');

  return `${days}d ${hours}h ${minutes}m ${seconds}s`;
}

const Countdown = ({setActive}) => {
  const [timeRemaining, setTimeRemaining] = useState(getTime(dayjs()));
  const [text, setText] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {

      const christmasDate = dayjs('2024-12-24T13:00:00');
      const now = dayjs();

      // Calculate the difference in days, hours, minutes, and seconds
      const duration = christmasDate.diff(now);

      if (duration <= 0) {
        clearInterval(interval);
        setActive(true);
      } else {
        setTimeRemaining(getTime(now));
      }
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, [setActive]);
  const send = ()=>{
    if (text.toLowerCase() === 'ich schwöre feierlich, dass ich ein flugpilot bin') {
      localStorage.setItem("active", "true");
      setActive(true);
    }
  }
  return (
         <>


    <div className="countdown no-select">
      <div className="no-select" style={{fontSize: 20, marginBottom: 3}}><strong>Anmeldung in</strong></div>
      <div className="no-select">{timeRemaining}</div>
      <div style={{marginTop: 8}}>
      <Stack alignItems="center">
      <TextField value={text} onChange={(e)=>setText(e.target.value)} sx={{marginTop: 1, width: 250,
            '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'rgba(80, 41, 50, 0.5)', // Change the border color here

                  },
                  '&:hover fieldset': {
                    borderColor: 'rgb(80, 41, 50, 100)', // Change border color on hover
                  },
                  '&.Mui-focused fieldset': {
                      borderWidth: 1, // Change border color when focused
                    },
                  '& input::placeholder': {
                      color: 'rgba(80, 41, 50, 0.5)', // Placeholder color
                      opacity: 1, // Optional: make sure the placeholder color is visible
                    },

                    '& .MuiInputBase-input': {
                      color: 'rgb(80, 41, 50)', // Text color
                    },  

                }
        }} size="small" variant="outlined"  placeholder="Schreib uns was..." color="info"/>
      <Button size="small" sx={{marginTop: 1}} onClick={send} color="info">Abschicken</Button>
      </Stack>
      </div>
    </div></>
  );
};
