// Capypara (c) 2024 Maximilian Reif
import { Link } from "react-router-dom";
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import {EventStateChip} from '../common/CustomComponents.js'

/// Render an event row
function OrgaEventItem({event}) {
  return <Link style={{ textDecoration: 'none', color: '#333' }} to={event.id}>
    <div className="event-row">
      <Stack spacing={1} sx={{margin: 1}}>
        <Stack direction="row" spacing={1}>
          <strong>{event.name}</strong>
        </Stack>
        <Stack direction="row" spacing={1}>
          <EventStateChip event={event}/>
          {event.orgadetails.newpax > 0 && <Chip size="small" color={event.eventstate === 0 ? 'primary' : ''} label={event.orgadetails.newpax + ' Neu'}/>}
          <Chip size="small" color="" label={event.orgadetails.currentpax + ' Teilnehmer | ' + event.orgadetails.plannedpax + ' Plätze'}/>
          {event.orgadetails.waitlistpax > 0 && <Chip size="small" color="" label={event.orgadetails.waitlistpax + ' Warteliste'}/>}
        </Stack>
      </Stack>
    </div>
  </Link>
}

/// Organizer event list
export default function ManageView({allEvents}) {
  return <Paper className="paper" elevation={6}>
    <div className={'color-bar default'}/>
    <div className="inner-box-paper">
      <h2>Events 2025</h2>
      {allEvents.map(x=><OrgaEventItem key={x.id} event={x}/>)}
    </div>
  </Paper>
}
