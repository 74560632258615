// Capypara (c) 2024 Maximilian Reif
import { useState } from 'react';
import { Link, useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';
import ParticipantSection from './ParticipantManager.js'
import {FormTextField, EventStateChip, ConfirmButton} from '../common/CustomComponents.js'
import Api from '../common/GraphQL.js';

/// Renders the title (event name + event state chip)
function Title({event}) {
  return <h2>
    <Stack direction="row" spacing={1}>
      <span>{event.name}</span>
      <EventStateChip event={event}/>
    </Stack>
  </h2>
}

/// Renders the event settings form
function EventForm({event}) {
  const flatEvent = {...event, ...event.orgadetails, changed: false};
  const [formValues, setFormState] = useState(flatEvent);
  const [updateEvent] = useMutation(Api.Mutation.UPDATE_EVENT, {refetchQueries: [Api.Query.GET_USER_DATA], ignoreResults: true});
  const setFormValues = newState=>{
    const changed = flatEvent.name !== newState.name ||
                  flatEvent.description !== newState.description ||
                  flatEvent.eventstart !== newState.eventstart ||
                  flatEvent.eventend !== newState.eventend ||
                  flatEvent.whatsapplink !== newState.whatsapplink
    setFormState({...newState, changed: changed})
  }

  // Update handlers
  const handleStartChange = x=>setFormValues({...formValues, eventstart: x && x.isValid() && x.format('YYYY-MM-DDTHH:mm:ss')});
  const handleEndChange = x=>setFormValues({...formValues, eventend: x && x.isValid() && x.format('YYYY-MM-DDTHH:mm:ss')});
  const handleSubmit = (notification)=>{
    setFormState({...formValues, changed: false});
    updateEvent({ variables: { event: {
      id: formValues.id,
      name: formValues.name,
      description: formValues.description,
      eventstart: formValues.eventstart,
      eventend: formValues.eventend,
      whatsapplink: formValues.whatsapplink,
      plannedpax: parseInt(formValues.plannedpax),
    },
    notification}})
  };

  const notifyWhatsapp = !event.whatsapplink && formValues.whatsapplink
  // If I don't have access rights for the event or the event is over –> prohibit changes
  const disabled = !event.orgadetails.myevent || event.eventstate !== 0;
  return <Paper elevation={6}>
    <div className="inner-box">
      <Title event={event}/>
      <Stack spacing={2}>
        <FormTextField disabled={disabled} name="name" label="Event Name" values={formValues} onChange={setFormValues}/>
        <FormTextField disabled={disabled} name="description" label="Beschreibung" values={formValues} onChange={setFormValues}/>
        <FormTextField disabled={disabled} name="plannedpax" label="Pax" values={formValues} onChange={setFormValues}/>
        <DateTimePicker disabled={disabled} ampm={false} label="Anfang" format="DD.MM.YYYY HH:mm" value={formValues.eventstart && dayjs(formValues.eventstart)} onChange={handleStartChange}/>
        <DateTimePicker disabled={disabled} ampm={false} label="Ende" value={formValues.eventend && dayjs(formValues.eventend)} format="DD.MM.YYYY HH:mm" onChange={handleEndChange}/>
        <FormTextField allowNull={true} disabled={disabled} name="whatsapplink" label="WhatsApp Link" values={formValues} onChange={setFormValues}/>
        {!disabled && !notifyWhatsapp && <Button variant="text" onClick={()=>handleSubmit()}>Speichern</Button>}
        {!disabled && notifyWhatsapp && <ConfirmButton variant="text" color="success" onClick={()=>handleSubmit('WHATSAPP')}
          description={`Wenn du jetzt den WhatsApp-Link ankündigst, wird eine E-Mail an alle ${event.orgadetails.currentpax} bestätigten Teilnehmer geschickt. Wenn später zusätzliche Teilnehmer bestätigt werden, wird der WhatsApp Link automatisch mit der Bestätigungsmail verschickt.`}
          confirmationtext="Speichern und ankündigen"
          cancellationtext="Abbrechen"
        >WhatsApp-Link ankündigen</ConfirmButton>}
        {!disabled && formValues.changed  && !notifyWhatsapp && <ConfirmButton 
          description={`Bist du sicher, dass du die Änderung per E-Mail schicken willst? Es wird eine E-Mail an alle ${event.orgadetails.currentpax} bestätigten Teilnehmer geschickt.`}
          confirmationtext="Speichern und ankündigen"
          cancellationtext="Abbrechen"
        variant="text" onClick={()=>handleSubmit('CHANGE')}>Speichern und Benachrichtigen</ConfirmButton>}
      </Stack>
    </div>
  </Paper>
}

/// Component with two buttons to conclude or cancel the whole event
function ConcludeOrCancelEvent({event}) {
  const mutationOptions = { variables: { eventid: event.id }, ignoreResults: true, refetchQueries: [Api.Query.GET_USER_DATA]}
  const [concludeEvent] = useMutation(Api.Mutation.CONCLUDE_EVENT, mutationOptions);
  const [cancelEvent] = useMutation(Api.Mutation.CANCEL_EVENT, mutationOptions);

  return <Paper elevation={6}>
    <div className="inner-box">
      <h2 style={{paddingBottom: 0}}>Event endgültig abschließen</h2>
      <Stack direction="row" spacing={1}>
        <ConfirmButton description={`Möchtest du sicher das Event abschließen? Das kann nicht rückgängig gemacht werden und alle ${event.orgadetails.currentpax} Teilnehmer erhalten eine E-Mail.`} onClick={concludeEvent} variant="outlined" color="success">Erfolgreich Durchgeführt</ConfirmButton>
        <ConfirmButton description={`Möchtest du sicher das Event absagen? Das kann nicht rückgängig gemacht werden und alle ${event.orgadetails.currentpax} Teilnehmer erhalten eine E-Mail.`} onClick={cancelEvent} variant="outlined" color="error">Absagen</ConfirmButton>
      </Stack>
    </div>
  </Paper>
}

/// Navigation back button
function BackButton() {
  return <>
    <Link style={{ textDecoration: 'none', color: '#333' }} to="/manage">
      <h2>
        <IconButton sx={{marginTop:-0.75}} size="small" color="primary"><ArrowBackIosIcon sx={{fontSize: 20}}/></IconButton>
        Übersicht
      </h2>
    </Link>
  </>
}

/// Event detail component
export default function ManageEventDetail({events}) {
  const {id} = useParams();
  const event = events.find(x=>x.id===id);
  return <>
    <BackButton/>
    <EventForm event={event}/>
    {event.orgadetails.myevent && <ParticipantSection event={event}/>}
    {event.orgadetails.myevent && event.eventstate === 0 && <ConcludeOrCancelEvent event={event}/>}
  </>
}